import axios from 'axios';
import KeyConstants from "../constants/KeyConstants";
// import router from '@/router';

// 配置 axios 默认设置
axios.defaults.baseURL = '/api';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;


export function

useFetch() {
    // 检查并刷新 token，如果快过期
    async function checkAndRefreshToken() {
        const accessToken = localStorage.getItem(KeyConstants.ACCESS_KEY);
        if (!accessToken) return;

        const tokenParts = accessToken.split('.');
        if (tokenParts.length !== 3) throw new Error('Invalid JWT format');

        const payload = JSON.parse(atob(tokenParts[1]));
        const currentTime = Math.floor(Date.now() / 1000);

        if (payload.exp - currentTime <= 60) {
            try {
                let refreshKey = localStorage.getItem(KeyConstants.REFRESH_KEY);
                if(!refreshKey){
                    return
                }
                let { data: tokens } = await axios.post('/refresh-token',{},{
                    // headers:{
                    //     'refresh-token': refreshKey,
                    //     'Authorization': `Bearer ${accessToken}`
                    // }
                });
                if(tokens.code / 100 !== 2){
                    throw new Error()
                }
                tokens = tokens['data']
                // localStorage.setItem(KeyConstants.Aens.accessToken);
                //                 // localStorage.setItem(KeyConstants.REFRESH_KEY, tokens.refreshToken);CCESS_KEY, tok
            } catch (err) {
                // TODO
                // console.error('Token refresh failed:', err.toString());
                throw err.toString();
            }
        }
    }

    // 组装请求头
    // eslint-disable-next-line no-unused-vars
    function buildHeaders(url) {
        const accessToken = localStorage.getItem(KeyConstants.ACCESS_KEY);
        const refreshToken = localStorage.getItem(KeyConstants.REFRESH_KEY);
        const headers = {};

        // 对于登录和刷新 token 请求，携带 refresh-token
        if (accessToken && !url.includes('/login')) {
            // 对其他请求，携带 accessToken
            headers['Authorization'] = `Bearer ${accessToken}`;
        }
        if (url.includes('/login') || url.includes('/refresh-token')) {
            headers['refresh-token'] = refreshToken;
        }

        return headers;
    }

    // 请求前拦截处理
    async function beforeRequest(method, url, data, options = {}) {
        if(!url.includes("/login") && !url.includes("/refresh-token")){
            // 刷新 token，如果必要
            await checkAndRefreshToken();
        }

        // 合并自定义 headers
        // options.headers = {...buildHeaders(url), ...options.headers};

        // 发送请求
        return axios[method](url, method === 'get' || method === 'delete' ? options : data, options);
    }

    // 请求后处理
    async function afterRequest(response) {
        try{
            let resp = await response;
            let data = resp['data'];

            if(data.code / 100 === 2){
                return Promise.resolve(data["data"])
            }else if(data.code === 401){
                // router.push({ name: 'Login', query: { redirect: router.currentRoute.fullPath } });
                return Promise.reject(data.message)
            }else{
                return Promise.reject(data.message)
            }
        }catch (e){
            return Promise.reject(e.toString())
        }


    }

    // 创建 fetch 代理
    const fetch = new Proxy(
        {},
        {
            get(target, prop) {
                if (typeof axios[prop] === 'function') {
                    return async (...args) => {
                        let url, data, options;
                        if(prop === 'delete' || prop === 'get'){
                            [url, data, options] = [args[0], null, args[1]]
                        }else if(prop === 'put' || prop === 'post'){
                            [url, data, options] = args.length === 2 ? [args[0], args[1],{}] : args;
                        }else{
                            throw new Error(`Method ${prop} is not supported by axios`);
                        }
                        // 处理方法和参数
                        // 调用请求前处理，并获取响应
                        const response = await beforeRequest(prop, url, data, options);
                        // 处理请求后逻辑
                        return await afterRequest(response);

                    };
                }
                throw new Error(`Method ${prop} is not supported by axios`);
            },
        }
    );

    return { fetch };
}