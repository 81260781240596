<template>
    <div>hello</div>
</template>

<script setup>
// @ is an alias to /src
import {useFetch} from "../composables/useFetch";
import {onMounted} from "vue";


const {fetch} = useFetch()
onMounted(
    ()=>{
        toPermission()
    }
)


function toPermission(){
    fetch.get('/permission')
}

</script>
