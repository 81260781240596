

const ACCESS_KEY = "hesenv:access_token"
const REFRESH_KEY = "hesenv:refresh_token"
const REDIR_KEY = "hesenv:before"

export default {
    ACCESS_KEY,
    REFRESH_KEY,
    REDIR_KEY
}