// utils/jwt.js
export function parseJWT(token) {
    try {
        const tokenParts = token.split('.');
        if (tokenParts.length !== 3) {
            throw new Error('Invalid JWT format');
        }
        const payload = JSON.parse(atob(tokenParts[1]));
        const data = payload['data']
        return JSON.parse(data)
    } catch (error) {
        console.error('Error parsing JWT:', error);
        return null;
    }
}