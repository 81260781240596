import {ref} from "vue";

export function useLoginError(){
    const erroring = ref(false)
    const errMsg = ref('')

    function startError(msg){
        erroring.value = true
        errMsg.value = msg
    }
    function endError(){
        erroring.value = false
        errMsg.value = ''
    }
    return {
        erroring,
        errMsg,
        startError,
        endError
    }
}