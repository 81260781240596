import {computed, ref} from "vue";
import {useFetch} from "./useFetch";
import KeyConstants from "../constants/KeyConstants";
import {useGlobalLoading} from "./useGlobalLoading";
import {useStore} from "vuex";
import {message} from "ant-design-vue";
import {parseJWT} from "../utils/jwt";
import {useRouter} from "vue-router";

const loginUrl = '/login'
const refreshUrl = '/refresh-token'

export function useUser() {
    const store = useStore();
    const router = useRouter();
    const { fetch } = useFetch();
    const { startLoading1, endLoading1 } = useGlobalLoading(store);

    const isLogin = computed(() => store.getters.isLogin);

    const loginLock = ref(false);

    // 检查权限
    const hasPermission = (per) =>
        (Array.isArray(per) ? per : [per]).every(perm => store.state.user.auth.includes(perm));

    // 登录
    const login = async ({ username, password,token }) => {
        if (loginLock.value) return;
        loginLock.value = true;
        startLoading1('login in...');
        try {
            const tokens = await fetch.post(loginUrl, { username, password },{
                headers:{
                    'cap-token': token,
                }
            });
            await toLocalStorage(tokens);
            message.success({ content: 'ok', duration: 1 });
        } finally {
            endLoading1();
            loginLock.value = false;
        }
    };

    // 刷新 token
    const refresh = async () => {
        // const accessToken = localStorage.getItem(KeyConstants.ACCESS_KEY);
        // const refreshToken = localStorage.getItem(KeyConstants.REFRESH_KEY);
        // if (!accessToken || !refreshToken) throw new Error('No tokens to refresh')

        try {
            const tokens = await fetch.post(refreshUrl);
            await toLocalStorage(tokens);
            return true;
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    // 登出
    const logout = async () => {
        startLoading1('Logging out...');
        try {
            localStorage.removeItem(KeyConstants.ACCESS_KEY);
            localStorage.removeItem(KeyConstants.REFRESH_KEY);
            await fetch.post('/logout');
            message.success("logout ok");
            router.push({name: 'Login'});
        } catch (e) {
            console.error(e);
        } finally {
            endLoading1();
        }
    };

    // 保存 tokens 到 localStorage 并更新 store
    const toLocalStorage = async (tokens) => {
        localStorage.setItem(KeyConstants.ACCESS_KEY, tokens['accessToken']);
        localStorage.setItem(KeyConstants.REFRESH_KEY, tokens['refreshToken']);
        const payload = parseJWT(tokens['accessToken']);
        await store.dispatch('login', payload);
    };

    return {
        isLogin,
        hasPermission,
        login,
        refresh,
        logout,
    };
}