import { createStore } from 'vuex';

const user = {
  state: {
    id: -1,
    username: '',
    auth: []
  },
  getters: {
    isLogin(state) {
      return !!state.username;
    },
    hasPermission: (state) => (permission) => {
      return state.auth.includes(permission);
    }
  },
  mutations: {
    login(state, { id,username, auth }) {
      state.id = id
      state.username = username;
      state.auth = [...auth];
    },
    logout(state) {
      state.id = -1;
      state.username = '';
      state.auth = [];
    }
  },
  actions: {
    login({ commit }, payload) {
      commit('login', payload);
    },
    logout({ commit }) {
      commit('logout');
    }
  }
};

const globalLoading = {
  state: {
    loading1: false,
    loading1msg: ''
  },
  mutations: {
    setLoading1(state, { loading, loading1msg = '' }) {
      state.loading1 = loading;
      if(loading){
        state.loading1msg = loading1msg;
      }
    }
  },
  actions: {
    setLoading1({ commit }, payload) {
      commit('setLoading1', payload);
    }
  }
};

export default createStore({
  modules: {
    user,
    globalLoading
  }
});