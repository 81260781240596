<template>
  <div id="nav">
      <router-view ></router-view>
  </div>
</template>
<script setup>
    import {useGlobalLoading} from "./composables/useGlobalLoading";
    import {watch} from "vue";
    import {message} from "ant-design-vue";

    const {loading1,loading1msg} = useGlobalLoading()


    watch(()=>loading1.value,(newL)=>{
        let key = 'loadingkey1'
        if(newL){
            message.loading({
                content: loading1msg.value,
                key,
                duration: 0
            })
        }else{
            message.destroy(key)
        }
    })
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
