import {useUser} from "../composables/useUser";
import KeyConstants from "../constants/KeyConstants";

export default async (to, from, next) => {
    const { hasPermission, isLogin, refresh } = useUser();

    const { requiresAuth, permissions } = to.matched.reduce(
        (acc, route) => ({
            requiresAuth: acc.requiresAuth || !!route.meta?.requiresAuth,
            permissions: acc.permissions.concat(route.meta?.requiredPermissions || []),
        }),
        { requiresAuth: false, permissions: [] }
    );
    // 记录当前路径
    const recordPath = () => to.name !== 'Login' && sessionStorage.setItem(KeyConstants.REDIR_KEY, to.fullPath);

    try {
        if (requiresAuth && !isLogin.value) {
            await refresh();
            if (!isLogin.value) {
                recordPath();
                return next({ name: 'Login' });
            }
        } else if (to.name === 'Login') {
            if(isLogin.value){
                return next({ name: 'Index' });
            }else{
                try{
                    await refresh();
                    if (isLogin.value) {
                        return next({ name: 'Index' });
                    }else{
                        next()
                    }
                }catch (e){
                    next()
                }


            }

        }
    } catch {
        recordPath();
        return next({ name: 'Login' });
    }

    hasPermission(permissions) ? next() : next(from.name ? false : { name: 'Index' });
}