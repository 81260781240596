import {computed} from "vue";
import {useStore} from "vuex";



export function useGlobalLoading() {
    const store = useStore()
    const loading1 = computed(() => store.state.globalLoading.loading1);
    const loading1msg = computed(() => store.state.globalLoading.loading1msg);

    function startLoading1(msg) {
        store.dispatch('setLoading1', { loading: true, loading1msg: msg }); // 修改为对象形式
    }

    function endLoading1() {
        store.dispatch('setLoading1', { loading: false, loading1msg: '' }); // 修改为对象形式
    }

    return {
        loading1,
        loading1msg,
        startLoading1,
        endLoading1
    };
}