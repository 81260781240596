import {createRouter,  createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import Login from "../components/Login.vue";
// import About from "../views/About.vue";
import checkAuth from "./checkAuth";

const routes = [
  {
    path: '/home',
    name: 'Index',
    alias: '/',
    component: Home,
    meta: {
      requiresAuth:true,
      requiredPermissions: []
    },
      // children: [
      //     {
      //         path: '/about',
      //         name: 'About',
      //         component: About,
      //         meta: {
      //             requiresAuth: true,
      //             requiredPermissions: ['order:read5']
      //         }
      //     }
      // ]
  },
    {
        path: '/login',
        name: 'Login',
        component: Login
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})
// 导航守卫
router.beforeEach(checkAuth);
export default router
